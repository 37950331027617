.main-page {
    background-color: #e0f2f7;
}
.main-page-bg {
    display: flex;
    flex-direction: column;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
    justify-content: center;
    background-position: center;
}
.background-second {
    display: flex;
    flex-direction: column;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: center;
    align-items: center;
}



.main-page-h1 {
    font-size: calc(12px + 24 * (100vw / 1920));
    text-align: center;
    margin: 2vh 5vw;
    font-weight: 1000;
    color: white;
}


.main-page-container {
    display: flex;
    justify-content: space-around;
    padding-bottom: 5vh;
    margin: 0 2vw;
    font-size: calc(8px + 10 * (100vw / 1920));
}
.content {
    margin-left: 2vw;
    margin-right: 2vw;
    display: flex;
    align-items: center;
    border-radius: 15px;
    background-color: #5ADFE8;
    width: fit-content;
    padding: 20px 20px;
}
.content img {
    max-width: 10vw;
}
.content-h3 {
    text-align: center;
    margin-left: 8px;
    font-size: calc(14px + 8 * (100vw / 1920));
}


.main-page-text-underhead {
    border-left: 1px solid;
    border-right: 1px solid;
    margin: 10vh 25vw;
    font-size: calc(14px + 8 * (100vw / 1920));
    text-align: justify;
}
.some-text {
    padding: 10px 10px;
    line-height: 34px;
}
.some-text ul li {
    list-style-type: none;
    margin-top: 10px;
    margin-left: -30px;
}

.main-page-text-underhead h4 {
    margin-top: 5px;
    text-align: center;
}

.main-page-upper-container {
    display: flex;
    justify-content: center;
    margin-left: 20px;
    margin-right: 20px;
}
.main-page-our-advantages {
    padding: 0px 4px;
    font-size: calc(16px + 20 * (100vw / 1920));
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    background-color: #49b5bd;
    text-align: center;
    color: rgb(255, 255, 255);
}
.upper-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.6rem;
    margin: 2vh 4vw;
}
.upper-container-first-text {
    font-size: calc(14px + 8 * (100vw / 1920));
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #5ADFE8;
    padding: 15px 15px;
    margin: 1vh 0;
    border-radius: 20px;
    width: 25vw;
}
.upper-container-second-text {
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.upper-container-image {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: aquamarine;
    border-radius: 20px;
    padding: 5px 5px;
}

@media (max-width: 1500px) {
    .main-page-text-underhead {
        margin-left: 4vw;
        margin-right: 4vw;
        margin-top: 6vh;
        margin-bottom: 6vh;
    }
    .some-text {
        line-height: 25px;
    }
}
@media (max-width: 1100px) {
    .main-page-upper-container {
        display: flex;
        flex-direction: column;
    }
    .upper-container-first-text {
        width: 70vw;
    }
}
@media (max-width: 700px) {
    .main-page-container {
        flex-direction: column;
        align-items: center;
    }
    .content {
        margin-bottom: 20px;
    }
}
