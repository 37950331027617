.contacts-main-container {
    height: 60vh;
    margin-top: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: right;
    background-size: contain;
    background-repeat: no-repeat;
}

.contacts-container{
    font-size: calc(12px + 12 * (100vw / 1920));
    width: fit-content;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 8px 10px rgba(0, 0, 0, .1);
    padding: 30px 30px;
    opacity: 0.9;
}
.contacts-container ul li {
    list-style-type: none;
}
.contacts-container a {
    color: black;
}


.contacts-icons {
    margin: 0px 0px;
    padding: 0px 0px;
    margin-left: -30px;
}