
.main-container {
    width: 60vw;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5vh;
}


.price-icons {
    margin-top: 4px;
    margin-right: 3px;
}
.price-icons-gnezdo {
    margin-top: 4px;
    margin-right: 3px;
}
.first-container ul h2 {
    font-size: calc(12px + 10 * (100vw / 1920));
}
.second-container ul h2 {
    font-size: calc(12px + 10 * (100vw / 1920));
}
.third-container ul h2 {
    font-size: calc(12px + 10 * (100vw / 1920));
}
.first-container ul #rooms {
    font-size: calc(12px + 10 * (100vw / 1920));
    margin-bottom: 12px;
}
.first-container ul li {
    display: flex;
    font-size: calc(12px + 10 * (100vw / 1920));
    padding-bottom: 3px;
}
.second-container ul li {
    display: flex;
    font-size: calc(12px + 10 * (100vw / 1920));
    padding-bottom: 3px;
}
.third-container ul li {
    display: flex;
    font-size: calc(12px + 10 * (100vw / 1920));
    padding-bottom: 3px;
}


.price-h1 {
    margin-top: 2vh;
    text-align: center;
}

.first-container {
    margin-top: 2vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.first-container ul {
    padding: 3vh 3vw;
    width: fit-content;
    height: fit-content;
    align-items: left;
    justify-content: center;
    list-style-type: none;
    background-color: white;
    opacity: 0.8;
    border-radius: 15px;
    box-shadow: 0 8px 10px rgba(0, 0, 0, .1);
}

.price-images {
    max-height: 20vh;
    max-width: 20vw;
    margin-right: 30px;
    margin-right: 30px;
    border-radius: 15px;
    box-shadow: 0 8px 10px rgba(0, 0, 0, .1);
}

.second-container {
    margin-top: 2vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.second-container ul {
    background-color: white;
    opacity: 0.8;
    padding: 3vh 3vw;
    width: fit-content;
    height: fit-content;
    align-items: left;
    justify-content: center;
    list-style-type: none;
    border-radius: 15px;
    box-shadow: 0 8px 10px rgba(0, 0, 0, .1);
}


.third-container {
    margin-top: 2vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.third-container ul {
    padding: 3vh 3vw;
    width: fit-content;
    height: fit-content;
    align-items: left;
    justify-content: center;
    list-style-type: none;
    background-color: white;
    opacity: 0.8;
    border-radius: 15px;
    box-shadow: 0 8px 10px rgba(0, 0, 0, .1);
}


.first-container ul .death-text {
    font-size: calc(12px + 10 * (100vw / 1920));
}


@media (max-width: 1920px) {
    .main-container {
        width: 70vw;
    }
}
@media (max-width: 1320px) {
}
@media (max-width: 920px) {
    .main-container {
        width: 90vw;
        
    }
    .price-images {
        display: none;
    }
}