* {
    margin: 0;
    pad: 0;
    font-family: "Manrope", sans-serif;
}
.App {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    background-color: #e0f2f7;
    overflow: hidden;
}



.header-main-text {
    z-index: 1;
    font-size: 20px;
    font-optical-sizing: auto;
    font-style: normal;
    background-color: #5ADFE8;

    display: flex;
    justify-content: center;
    align-items: center;


    margin: 0px 0px;
    padding: 0px 0px;
    height: 120px;

    box-shadow: 10px 8px 10px rgba(0, 0, 0, .1);
}


.logo-image {
    height: 50px;
    margin-right: 10px;
}
.logo {
    font-size: 32px;
    font-weight: 1000;
    text-decoration: none;
    color: white;
    transition: all 0.1s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
}
.logo:hover {
    color: black;
}

.nav-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.navbar-menu {
    display: flex;
    list-style-type: none;
}
.footer-menu {
    display: flex;
    list-style-type: none;
    padding: 0px 0px;
    margin: 0px 0px;
}
.footer-menu-second {
    display: flex;
    list-style-type: none;
    padding: 0px 0px;
    margin: 0px 0px;
}
.navbar-menu li {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 20px;
}
.footer-menu li {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 10px;
}
.footer-menu-second li {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 10px;
}

.navbar-menu li .active {
    color: white;
    font-weight: 600;
}
.navbar-links {
    text-decoration: none;
    color: #000000;
}

.nav-icons-link {
    color: black;
}
.nav-icons-link-header {
    color: black;
}
.nav-icons {
    margin-top: 2px;
}

.navbar-number a {
    text-decoration: none;
    color: rgb(0, 0, 0);
    font-weight: 700;
    text-decoration: underline;
}
.footer-number a {
    text-decoration: none;
    color: black;
}




footer {
    margin-top: auto;
    background-color: #5ADFE8;
    box-shadow: 0px -4px 10px rgba(0, 0, 0, .2);
    z-index: 1;
}

.footer-container {
    margin-top: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    font-size: 20px;
}
.email-footer {
    text-decoration: none;
    color: black;
    margin-left: 15px;
}
.navbar-number-a {
    margin-left: 15px;
}

.navbar-input {
    display: none;
}
.nav-container label {
    display: none;
    font-size: 3rem;
    color: #32899e;
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
}


@media (max-width: 800px) {
    .nav-container {
        flex-direction: row;
        padding: 0px 20px;
    }
    .nav-icons-link-header {
        display: none;
    }
    .nav-container label {
        margin-right: 20px;
        display: flex;
        visibility: visible;
        opacity: 1;
    }
    .navbar-number-a {
        margin-left: 0px;
    }
    .nav-links {
        display: flex;
        flex-direction: column;
        position: absolute;
        left: 0;
        top: 120px;
        background: #5ADFE8;
        width: 100%;
        height: 130px;
        padding: 1rem 0;
        display: block;
        border-top: .1rem solid rgba(0, 0, 0, 0.1);
        box-shadow: 0 4px 10px rgba(0, 0, 0, .1);
        transform: scale(0);
        opacity: 0;
        text-align: center;
        font-size: 20px;
    }
    .logo {
        font-size: 24px;
    }
    .navbar-menu {
        flex-direction: column;
        text-align: left;
    }
    .nav-container .nav-links li {
        display: block;
        padding-bottom: 6px;
    }
    
    .nav-container .navbar-input:checked ~ .nav-links {
        transform: scale(1);
        opacity: 1;
    }



    .footer-container {
        height: 100px;
        font-size: calc(12px + 10 * (100vw / 1920));
    }
    .footer-menu-second {
        list-style-type: none;
    }
    .footer-menu {
        display: flex;
        flex-direction: column;
    }
}